import React from 'react';
import * as PropTypes from 'prop-types';

import useStyles from './styles';

const DescriptionBox = (props) => {
    const { children } = props;

    const classes = useStyles();

    return <div className={classes.root}>{children}</div>;
};

DescriptionBox.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DescriptionBox;
