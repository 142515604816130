import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        backgroundColor: '#F8FAFF',
        padding: theme.spacing(7, 5),
        margin: theme.spacing(2, 0),
        borderRadius: 40,
    },
}));
