import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import BookADemoButton from '$landing-page/components/book-a-demo-button';
import StartNowButton from '$landing-page/components/start-now-button';

const CTAButtons = () => {
    const theme = useTheme();
    const isScreenSizeBelowSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid container spacing={2} justify={isScreenSizeBelowSm ? 'center' : 'flex-start'}>
            <Grid item>
                <StartNowButton />
            </Grid>
            <Grid item>
                <BookADemoButton />
            </Grid>
        </Grid>
    );
};

export default CTAButtons;
