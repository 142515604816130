import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    headerFeatureItemRoot: {
        marginTop: '0',
        marginBottom: '0',
    },
    headerFeatureItemTitle: {
        color: '#081f4d !important',
    },
    headerFeatureItemImage: {
        [theme.breakpoints.down('xs')]: {
            width: '110%',
            marginTop: theme.spacing(-3),
            marginLeft: theme.spacing(-1),
        },
        [theme.breakpoints.up('xs')]: {
            maxHeight: '400px',
            '& [data-main-image]': {
                objectFit: 'contain !important',
            },
        },
    },
    publicationsWrapper: {
        marginTop: theme.spacing(2),
    },
    publicationsHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    publicationsHeaderButton: {
        fontSize: theme.typography.pxToRem(12),
    },
    sectionTitle: {
        fontSize: theme.typography.pxToRem(30),
        fontWeight: 700,
        color: '#081F4D',
        margin: 0,
    },
    sectionsLinkWrapper: {
        borderBottom: '1px solid #E2E2E2',
        paddingBottom: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        margin: theme.spacing(3, 0),
    },
    sectionLink: {
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
        color: '#081F4D',
        cursor: 'pointer',
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 700,
        marginRight: theme.spacing(4),
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}));
